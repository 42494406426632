import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/comm.css";
import api from "@/http/api";
import http from "@/http/http";

// axios 拦截器
import "@/http/axios";
// 对后端接口 进行全局注册
Vue.prototype.$api = api;
// 对请求方式 进行全局注册
Vue.prototype.$http = http;

Vue.config.productionTip = false;
router.afterEach((to,from,next) => {
    window.scrollTo(0,0);
});
Vue.use(ElementUI);
new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
