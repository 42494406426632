import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		currNav: "home",
		phone: "" || sessionStorage.getItem("phone"),
	},
	getters: {
		phone: (state) => state.phone,
	},
	mutations: {
		ChangeCurrNav(state, currNav) {
			state.currNav = currNav;
		},
		phone(state, val) {
			Vue.set(state,'phone',val)
			sessionStorage.setItem("phone", val);
		  },
	},
	actions: {},
	modules: {},
});
