<template>
	<div class="header"  :class="show ? 'h5' : ''">
		<div class="header-content Gwidth flex items-center space-between">
			<div class="logo">
				<router-link to="/">
					<img src="@/assets/images/xrlogo.png" alt="星润科技" />
				</router-link>
			</div>
			<nav>
				<ul class="flex items-center">
					<li class="nav-item" :class="{ active: currNav == 'home' }">
						<router-link to="/">首页</router-link>
					</li>
					<li
						class="nav-item"
						:class="{ active: currNav == 'product'||currNav == 'about' }"
					>
						<router-link to="/product">产品</router-link>
					</li>
					<li class="nav-item" :class="{ active: currNav == 'down' }">
						<router-link to="/down">下载</router-link>
					</li>
					<li class="nav-item" :class="{ active: currNav == 'news' }">
						<router-link to="/news">教程</router-link>
					</li>
					<li
						class="nav-item"
						:class="{ active: currNav == 'cooperate' }"
					>
						<router-link to="/cooperate">合作</router-link>
					</li>
				</ul>
			</nav>
			<div class="right-content flex items-center">				
				<div class="need">
					<img src="@/assets/images/user.png" alt="沟通需求" />
					<!-- <router-link to="/">沟通需求</router-link> -->
				</div>
			</div>
			<div class="menu">
				<i class="el-icon-s-fold" @click="mshow = !mshow"></i>
			</div>
			<transition name="el-zoom-in-center">
				<ul class="m-nav" v-show="mshow" @click="mshow = false">
					<li class="nav-item" :class="{ active: currNav == 'home' }">
						<router-link to="/">首页</router-link>
					</li>
					<li
						class="nav-item"
						:class="{ active: currNav == 'product' }"
					>
						<router-link to="/product">产品</router-link>
					</li>
					<li class="nav-item" :class="{ active: currNav == 'down' }">
						<router-link to="/down">下载</router-link>
					</li>
					<li class="nav-item" :class="{ active: currNav == 'news' }">
						<router-link to="/news">教程</router-link>
					</li>
					<li
						class="nav-item"
						:class="{ active: currNav == 'cooperate' }"
					>
						<router-link to="/cooperate">合作</router-link>
					</li>
				</ul>
			</transition>
		</div>
	</div>
</template>
<script>
	import { mapMutations, mapState } from "vuex";
	export default {
		data() {
			return {
				show: false,
				mshow:false,
			};
		},
		mounted(){
			if (this._isMobile()) {
			this.show = true;
			} else {
			this.show = this.$store.getters.phone;
			}
		},
		computed: {
			...mapState(["currNav"]),
		},
		methods:{
			_isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
		}
	};
</script>
<style lang="scss" scoped>
	.header {
		background: transparent;
		backdrop-filter: blur(15px);
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		border-bottom: 1px solid #c8c9cc;
		.logo {
			font-size: 0;
			img {
				width: 105px;
				height: auto;
			}
		}
		.nav-item {
			a {
				font-size: 16px;
				font-weight: 400;
				color: #1d2129;
			}
			&.active {
				a {
					font-weight: 600;
					color: #2b6bff;
				}
			}
		}
		.right-content {
			.tel {
				background: url(@/assets/images/icon-phone.png) left center
					no-repeat;
				background-size: 24px 24px;
				padding-left: 26px;
				a {
					font-size: 18px;
					font-weight: 400;
					color: #1d2129;
				}
			}
			.need {
				width: 100px;
				height: 43px;
				img{
					width: 70px;
					height: 43px;
				}
				a {
					display: inline-block;
					background: #2b6bff;
					font-size: 14px;
					font-weight: 400;
					color: #ffffff;
					text-align: center;
				}
			}
		}
	}
	

	@media screen and (min-width: 0px) {
		.header {
			padding: 10px 0;
			height: 65px;
			z-index: 999;
			box-sizing: border-box;
			nav,
			.tel,
			.need {
				display: none;
			}
			.menu {
				display: block;
				font-size: 30px;
			}
			.m-nav {
				position: absolute;
				top: 100%;
				left: 0;
				z-index: 999;
				width: 100%;
				height: calc(100vh - 65px);
				overflow: hidden;
				background-color: #ffffff;
				text-align: center;
				li {
					border-bottom: 1px solid #eeeeee;
					a {
						line-height: 50px;
					}
				}
			}
		}
	}
	@media screen and (min-width: 760px) {
		.header {
			height: 100px;
			padding: 23px 0;
			nav {
				display: block;
				ul {
					li {
						a {
							padding: 0 25px;
						}
					}
				}
			}
			.menu {
				display: none;
			}
		}
	}
	@media screen and (min-width: 820px) {
		.header {
			.need {
				display: block;
				a {
					width: 84px;
					height: 36px;
					border-radius: 19px;
					line-height: 36px;
				}
			}
		}
	}
	@media screen and (min-width: 1100px) {
		.header {
			.tel {
				display: block;
			}
			.need {
				margin-left: 15px;
			}
		}
	}
	@media screen and (min-width: 1300px) {
		.header {
			&-content {
				.need {
					margin-left: 40px;
					a {
						width: 104px;
						height: 36px;
						border-radius: 19px;
						line-height: 36px;
					}
				}
			}
		}
	}
</style>
