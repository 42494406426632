import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "home",
		component: () => import("../views/index.vue"),
	},
	{
		path: "/product",
		name: "product",
		component: () => import("../views/product.vue"),
	},
	{
		path: "/down",
		name: "down",
		component: () => import("../views/down.vue"),
	},
	{
		path: "/news",
		name: "news",
		component: () => import("../views/news.vue"),
	},
	{
		path: "/about",
		name: "about",
		component: () => import("../views/about.vue"),
	},
	{
		path: "/cooperate",
		name: "cooperate",
		component: () => import("../views/cooperate.vue"),
	},
	
];

const router = new VueRouter({
	// mode: "history",
	base: process.env.BASE_URL,
	routes,
});
router.beforeEach((to, from, next) => {
	// console.log("[路由]", to);
	store.commit("ChangeCurrNav", to.name);
	next();
});
export default router;
