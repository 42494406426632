<template>
	<footer :class="show ? 'h5' : ''">
		<div class="smtop">
		<ul class="footer-top Gwidth">
			<li>10年技术沉淀</li>
			<li>50+技术团队</li>
			<li>1000+客户案例</li>
			<li>7*24小时售后</li>
		</ul>
		</div>
		<div class="footer-center Gwidth">
			<div class="footer-center-left">
				<div class="footer-center-left-item">
					<h5>产品系列</h5>
					<ul>
						<li>小盒子</li>
						<li>X86</li>
						<li>工控机</li>
					</ul>
				</div>
				<div class="footer-center-left-item">
					<h5>服务支持</h5>
					<ul>
						<li>常见问题</li>
						<li>APP下载</li>
						<li>安装教程</li>
					</ul>
				</div>
				<div class="footer-center-left-item">
					<h5>友情合作</h5>
					<ul>
						<li>大客户</li>
						<li>渠道合作</li>
					</ul>
				</div>
			</div>
			<div class="footer-center-right">
				<div class="footer-center-right-item">
					<h5></h5>
					<div class="phone">
						<div class="img">
							<img src="@/assets/ico/icon-phone.png" alt="" />
						</div>
						<p class="title">010-89513669</p>
						<a href="tel:010-89513669">联系我们</a>
					</div>
				</div>
				<div class="footer-center-right-item">
					<div class="gzh">
						<div class="img">
							<img src="@/assets/images/gzh.png" alt="" />
						</div>
						<p class="title">官方微信群</p>
					</div>
				</div>
				<div class="footer-center-right-item">
					<h5></h5>
					<div class="gzh">
						<div class="img">
							<img src="@/assets/images/wx.png" alt="" />
						</div>
						<p class="title">官方公众号</p>
					</div>
				</div>
				
			</div>
		</div>
		<div class="footer-bottom Gwidth">
			<div class="footer-bottom-ico">
				<img src="@/assets/images/footer.png" alt="" />
			</div>
			<div class="optxt">
				COPYRIGHT@北京星润时代网络科技有限公司 2013-2022京ICP备1455839号-4站长工具 
				<a class="ahref" href="https://www.xingrunkeji.cn/index/icp.pdf"
					>京网公安备：11011502005462号
				</a>
				增值电信业务经营许可证：B-20235590
			</div>
		
		</div>
	</footer>
</template>
<script>
	export default {
		data() {
			return {
				show:false,
			};
		},
		mounted() {
			if (this._isMobile()) {
			this.show = true;
			} else {
			this.show = this.$store.getters.phone;
			}
		},
		methods:{
			_isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
		}
	};
</script>
<style lang="scss" scoped>
	@media screen and (min-width: 0px) {
		footer {
			padding: 0px 0 40px 0;
			background: #2A354F;
			.Gwidth{
				overflow: auto;
			}
			.smtop{
				width: 100%;
				padding-top: 30px;
				background: #ffffff;
			}
			.footer-top {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
				li {
					padding-left: 38px;
					font-size: 16px;
					line-height: 30px;
					font-weight: 600;
					color: #1d2129;
					width: 50%;
					box-sizing: border-box;
					margin-bottom: 20px;
					&:nth-child(1) {
						background: url(@/assets/images/f01.png) left
							center no-repeat;
						background-size: 29px 29px;
					}
					&:nth-child(2) {
						background: url(@/assets/images/f02.png) left
							center no-repeat;
						background-size: 29px 29px;
					}
					&:nth-child(3) {
						background: url(@/assets/images/f03.png) left
							center no-repeat;
						background-size: 29px 29px;
					}
					&:nth-child(4) {
						background: url(@/assets/images/f04.png) left
							center no-repeat;
						background-size: 29px 29px;
					}
				}
			}
			.footer-center {
				padding: 30px 0;
				&-left {
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					padding-bottom: 30px;
					&-item {
						h5 {
							font-size: 14px;
							font-weight: 600;
							color: #ffffff;
							line-height: 20px;
							position: relative;
							&::after{
					content: '';
					width: 5px;
					height: 18px;
					background: #3B6FEA;
					border-radius: 6px 6px 6px 6px;
					position: absolute;
					top: 50%;
					left: -14px;
					transform: translateY(-50%);
				}
						}
						ul {
							margin-top: 10px;
							li {
								font-size: 14px;
								font-weight: 400;
								color: #ffffff;
								line-height: 24px;
							}
						}
					}
				}
				&-right {
					padding-top: 30px;
					display: flex;
					align-items: flex-end;
					justify-content: space-between;
					&-item {
						width: 33%;
						text-align: center;
						&:nth-child(1) {
							width: 34%;
						}
						h5 {
							font-size: 14px;
							font-weight: 400;
							color: #1d2129;
							text-align: left;
						}
						.gzh {
							margin-top: 10px;
							img {
								width: 64px;
								height: 64px;
							}
							.title {
								font-size: 12px;
								font-weight: 400;
								color: #ffffff;
							}
							.des {
								font-size: 14px;
								font-weight: 400;
								color: #646566;
							}
						}
						.phone {
							img {
								width: 28px;
								height: 28px;
							}
							.title {
								font-size: 16px;
								font-weight: 500;
								color: #ffffff;
							}
							a {
								display: block;
								width: 100%;
								max-width: 192px;
								height: 36px;
								background: #2e51fb;
								margin: 0 auto;
								margin-top: 24px;
								font-size: 14px;
								line-height: 36px;
								font-weight: 400;
								color: #ffffff;
							}
							margin-bottom: 10px;
						}
					}
				}
			}
			.footer-links {
				padding: 16px 0;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				h5 {
					font-size: 14px;
					font-weight: 400;
					color: #ffffff;
					margin-bottom: 10px;
				}
				a {
					font-size: 14px;
					font-weight: 400;
					color: #ffffff;
					margin-right: 24px;
					margin-bottom: 10px;
				}
			}
			.footer-bottom {
				border-top: 1px solid #767676;
				padding-top: 16px;
				font-size: 14px;
				font-weight: 400;
				color: #ffffff;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				a {
					color: #ffffff;
				}
				&-ico {
					img {
						width: 187px;
						height: 34px;
						margin-right: 10px;
						&:nth-child(2) {
							width: 98px;
							height: 31px;
						}
					}
				}
				.optxt{
					line-height: 34px;
					font-size: 14px;
					white-space: nowrap;
				}
			}
		}
	}
	@media screen and (min-width: 760px) {
		footer {
			.footer-top {
				li {
					width: auto;
					margin-bottom: 20px;
				}
			}
		}
	}
	@media screen and (min-width: 820px) {
		footer {
			.footer-center {
				display: flex;
				justify-content: space-between;
				&-left {
					width: calc(50% - 20px);
					border-bottom: 0;
					padding-bottom: 0px;
					position: relative;
					
				}
				&-right {
					width: calc(50% - 20px);
					padding-top: 0px;
				}
			}
		}
	}
	@media screen and (min-width: 1100px) {
	}
	@media screen and (min-width: 1300px) {
		footer {
			.footer-center {
				&-left {
					width: calc(50% - 70px);
					&::after {
						right: -70px;
					}
				}
				&-right {
					width: calc(50% - 70px);
				}
			}
		}
	}
</style>
