<template>
	<div id="app">
		<div class="nav-page">
			<Header></Header>
		</div>
		<router-view />
		<Footer></Footer>
	</div>
</template>
<script>
	import Header from "@/components/header.vue";
	import Footer from "@/components/footer.vue";
	export default {
		components: { Header, Footer },
		provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      screenWidth: document.body.clientWidth
    };
  },
		mounted(){
  window.onresize = () => {
        return (() => {          
            window.screenWidth = document.body.clientWidth
            this.screenWidth = window.screenWidth  
            // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
            this.show= this.screenWidth<800?true:''
			console.log(this.show);
            this.$store.commit("phone", this.show);
        })()
    }
},
	};
</script>

<style lang="scss">
	// @media screen and (min-width: 0px) {
	// 	.nav-page {
	// 		height: 65px;
	// 	}
	// }
	// @media screen and (min-width: 760px) {
	// 	.nav-page {
	// 		height: 70px;
	// 	}
	// }
</style>
